import React from 'react';
import PropTypes from 'prop-types';

import Micro from '../layouts/Micro';
import SEO from '../components/SEO/SEO';
import Hero from '../components/Hero/Hero';
import CustomCard from '../components/CustomCard/CustomCard';

const LegalPartnerDetail = ({ pageContext, location }) => {
  const { name, description } = pageContext;
  return (
    <Micro>
      <SEO title={name} description={description} />

      <Hero title={name} location={location} crumbLabel={name} isNeedMainLabel={false} />

      <article className="container flex-center">
        <div style={{ width: '60vw', maxWidth: '400px', margin: '3rem auto', textAlign: 'center' }}>
          <CustomCard info={pageContext} />
        </div>
      </article>
    </Micro>
  );
};

LegalPartnerDetail.propTypes = {
  props: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.instanceOf(LegalPartnerDetail.props), // eslint-disable-line
  pageContext: PropTypes.instanceOf(LegalPartnerDetail.props), // eslint-disable-line
};

export default LegalPartnerDetail;
